<template>
  <v-app>
    <v-app-bar app dark dense clipped-right color="secondary" v-if="!hideAppBar">
      <v-app-bar-nav-icon v-if="mobile || assessment" @click.stop="drawerToggle = !drawerToggle"></v-app-bar-nav-icon>
      <v-toolbar-title @click="goHome()">
        <v-img :src="require('@/assets/layoutLogo.gif')" height="30" contain class="ml-5" position="left"
      /></v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-navigation-drawer fixed app width="210" temporary v-model="drawerToggle" light v-if="mobile || assessment">
      <v-list>
        <v-list-item v-if="isLoggedIn && profile">
          <v-list-item-content>
            <v-list-item-title class="text-h6"> {{ profile.firstName }} {{ profile.lastName }} </v-list-item-title>
            <v-list-item-subtitle> {{ role === "cp" ? "ARC" : plan }} Plan </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-else>
          <v-list-item-title class="text-h6"> Hello There </v-list-item-title>
          <v-btn icon @click.stop="drawerToggle = !drawerToggle">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list nav dense v-if="role === 'cp'">
        <v-list-item class="blue--text text-accent-4" exact to="/partner">
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>Partner Dashboard</v-list-item-content>
        </v-list-item></v-list
      >
      <v-divider v-if="role === 'cp'"></v-divider>
      <v-list nav dense>
        <v-list-item-group v-model="group" active-class="blue--text text-accent-4">
          <div v-for="(item, i) in menuItems" :key="i">
            <div>
              <v-list-item exact :to="item.link">
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>{{ item.title }}</v-list-item-content>
              </v-list-item>
            </div>
          </div>
          <v-list-item exact @click="openSupport">
            <v-list-item-icon>
              <v-icon>mdi-account-question</v-icon>
            </v-list-item-icon>
            <v-list-item-content>Support</v-list-item-content>
          </v-list-item>
          <v-list-item v-if="isLoggedIn" @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-exit-to-app</v-icon>
            </v-list-item-icon>
            <v-list-item-content>Logout</v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid class="px-6">
        <v-banner
          v-if="isErrorMessage"
          elevation="8"
          single-line
          color="error"
          transition="slide-y-transition"
          class="mb-6">
          <v-icon slot="icon" color="white" size="36"> mdi-alert-circle-outline</v-icon>
          <span class="white--text">{{ errorMessage }}</span>
          <template v-slot:actions>
            <v-btn text color="white" @click.stop="dismissAlert">DISMISS</v-btn>
          </template>
        </v-banner>
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-navigation-drawer
      permanent
      expand-on-hover
      app
      right
      color="primary"
      v-if="!mobile && !hideAppBar && !assessment"
      class="pa-4"
      mini-variant-width="100"
      :mini-variant="true">
      <v-img class="mt-6 mb-4" :src="require('@/assets/layoutIcon.png')" height="50" contain position="center" />
      <v-divider color="white"></v-divider>
      <v-list>
        <v-list-item v-if="isLoggedIn && profile" active-class="secondary--text" class="white--text">
          <v-list-item-avatar>
            <v-img :src="avatarPicker"></v-img>
          </v-list-item-avatar>
          <v-list-item-title>
            <span class="font-weight-bold">{{ profile.firstName }} {{ profile.lastName }}</span
            ><BR /> {{ role === "cp" ? "ARC" : plan }} Plan
          </v-list-item-title>
        </v-list-item>
      </v-list>
      <v-divider color="white"></v-divider>
      <v-list nav dense v-if="role === 'cp'">
        <v-list-item class="white--text" exact to="/partner">
          <v-list-item-icon>
            <v-icon color="white">mdi-view-dashboard-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>Partner Dashboard</v-list-item-content>
        </v-list-item></v-list
      >
      <v-divider color="white" v-if="role === 'cp'"></v-divider>
      <v-list nav dense>
        <v-list-item-group v-model="group">
          <div v-for="(item, i) in menuItems" :key="i" class="my-3">
            <v-list-item class="white--text" exact :to="item.link">
              <v-list-item-icon>
                <v-icon color="white">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>{{ item.title }}</v-list-item-content>
            </v-list-item>
          </div>
          <v-list-item class="white--text" @click="openSupport">
            <v-list-item-icon>
              <v-icon color="white">mdi-account-question</v-icon>
            </v-list-item-icon>
            <v-list-item-content>Support</v-list-item-content>
          </v-list-item>
          <v-list-item class="white--text" v-if="isLoggedIn" @click="logout">
            <v-list-item-icon>
              <v-icon color="white">mdi-exit-to-app</v-icon>
            </v-list-item-icon>
            <v-list-item-content>Logout</v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-snackbar :timeout="-1" :value="isRefresh" absolute left shaped top class="mt-10">
      New content is available.
      <template v-slot:action="{ attrs }">
        <v-btn color="secondary" text v-bind="attrs" @click="update"> Update </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import Auth from "@/plugins/auth";
import api from "@/plugins/api";
import { mapGetters } from "vuex";

export default {
  name: "App",
  data() {
    return {
      group: null,
      drawerToggle: false,
      assessment: false,
      registration: null,
      isRefresh: false,
      refreshing: false,
    };
  },
  computed: {
    ...mapGetters(["ares", "isLoggedIn", "profile", "isErrorMessage", "errorMessage", "hideAppBar"]),
    avatarPicker() {
      var profile = this.profile;
      if (profile && profile.linkedAvatar && profile.linkedAvatar !== "") {
        return profile.linkedAvatar;
      } else {
        var aurl =
          "https://ui-avatars.com/api/?name=" +
          profile.firstName +
          "+" +
          profile.lastName +
          "&color=ddd&background=06AAFC&size=100";
        return aurl;
      }
    },
    aresDone() {
      if (this.ares) {
        if (this.ares.submitted) {
          return true;
        }
      }
      return false;
    },
    mobile() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return true;
      } else {
        return false;
      }
    },
    menuItems() {
      let menuItems = [
        {
          icon: "mdi-lock-open",
          title: "Login",
          short: "Login",
          link: "/login",
        },
        {
          icon: "mdi-account-circle",
          title: "Sign up",
          short: "Sign up",
          link: "/plans",
        },
        // { icon: "mdi-help", title: "Help", link: "/help" },
      ];

      if (this.isLoggedIn) {
        menuItems = [];

        if (this.role !== "cp") {
          menuItems.push({
            icon: "mdi-home",
            title: "Risk Dashboard",
            short: "Home",
            link: "/",
          });
          //     if (this.aresDone) {
          menuItems.push(
            {
              icon: "mdi-file-document",
              title: "Policy Builder",
              link: "/policy",
              short: "Policy",
            },
            {
              icon: "mdi-file-document-multiple",
              title: "Forms Library",
              link: "/forms",
              short: "Forms",
            }
          );
          // }
          if (this.plan !== "Intelligent") {
            menuItems.unshift({
              icon: "mdi-star-face",
              title: "Upgrade Plan",
              short: "Upgrade",
              link: "/upgrade",
            });
          }

          // set the admin to allow access for admin user
          if (this.role === "admin") {
            // if (this.manageUsers === 'admin') {
            menuItems.unshift({
              icon: "mdi-account-multiple",
              title: "Admin Panel",
              link: "/admin",
              short: "Admin",
            });
          }

          menuItems.push({
            icon: "mdi-account-circle",
            title: "My Account",
            link: "/account",
            short: "Account",
          });
        } else {
          menuItems.push({
            icon: "mdi-account-circle",
            title: "My Account",
            link: "/partner/account",
            short: "Account",
          });
        }
      }

      return menuItems;
    },
    plan() {
      if (this.profile) {
        if (this.profile.company && this.profile.company.plan) {
          if (this.profile.company.plan.name) {
            return this.profile.company.plan.name.charAt(0).toUpperCase() + this.profile.company.plan.name.slice(1);
          }
        }
      }

      return "Bright";
    },
    role() {
      if (this.profile) {
        if (this.profile.role) {
          if (this.profile.role === "admin") {
            return "admin";
          }
          if (this.profile.role === "cp") {
            return "cp";
          }
        }
      }
      return "user";
    },
  },
  beforeCreate() {
    // Check localstorage to see if a user is already logged in
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    // console.log("accessToken >> ", accessToken)
    // console.log("refreshToken >> ", refreshToken)
    if (accessToken && refreshToken) {
      api.get("/auth/profile").then((response) => {
        const profile = response.data.profile;
        this.$store.dispatch("setProfile", profile);
        localStorage.setItem(
          "profile",
          JSON.stringify({
            firstName: profile.firstName,
            lastName: profile.lastName,
            role: profile.role,
            company: {
              plan: profile.company.plan,
            },
          })
        );
        // check to see if they already have an ares and if so, send it to state
        if (!profile.channelPartner) {
          api
            .get("/ares")
            .then((response) => {
              this.$store.dispatch("setAres", response.data);
            })
            .catch(() => {
              this.$store.dispatch("setAres", {});
            });
        }
      });
    }
  },
  created() {
    document.addEventListener("serviceWorkerUpdateEvent", this.appUpdateUI, {
      once: true,
    });
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) return;
      this.refreshing = true;
      window.location.reload();
    });
  },
  mounted() {},
  watch: {
    $route() {
      this.$store.dispatch("clearErrorMessage");
      //(this.$router.currentRoute);
      if (
        this.$router.currentRoute.name === "riskAssessment" ||
        this.$router.currentRoute.name === "multipleLocation" ||
        this.$router.currentRoute.name === "newriskAssessment"
      ) {
        this.assessment = true;
      } else {
        this.assessment = false;
      }
    },
  },
  methods: {
    appUpdateUI: function (e) {
      this.registration = e.detail;
      this.isRefresh = true;
    },
    update() {
      this.isRefresh = false;
      if (this.registration || this.registration.waiting) {
        this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
      }
    },
    logout() {
      Auth.logout();
    },
    goHome() {
      this.$router.push("/");
    },
    dismissAlert() {
      this.$store.dispatch("clearErrorMessage");
    },
    openSupport() {
      // https://smarterrisk.freshdesk.com/support/home
      location.href = "https://smarterrisk.freshdesk.com/support/home";
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

.v-btn {
  text-transform: none !important;
}
</style>
