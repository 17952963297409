import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import general from './general'
import ares from './ares'
import arc from './arc'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    general,
    ares,
    arc,
  }
})