import store from "../store";

export default {
  // this function guards routes so that admin only have access
  admin(to, from, next) {
    const loggedIn = isAuthorized();
    if (!loggedIn) {
      store.dispatch("setErrorMessage", "You must be logged in to go there.");
      next({
        path: "/login",
        // query: { redirect: to.fullPath }
      });
    } else {
      const role = getRole();
      if (role === "admin") {
        // all good, let em go
        next();
      } else {
        // they don't have the grant, they need to go pay for it
        store.dispatch(
          "setErrorMessage",
          "Error: this page is for Admins only.",
        );
        next({
          path: "/",
        });
      }
    }
  },

  // this guards routes so that you have to be logged in to see the page
  // it also checks for grants and roles in the meta tag
  route(to, from, next) {
    const loggedIn = isAuthorized();
    if (!loggedIn) {
      if (to.path !== "/") {
        store.dispatch("setErrorMessage", "You must be logged in to go there.");
      }
      next({
        path: "/login",
        // query: { redirect: to.fullPath }
      });
    } else {
      // OK so they are logged in, but let's check to see if they need to pay to access this page
      const role = getRole();
      if (to.path === "/" && role === "cp") {
        next({ path: "/partner" });
      }
      if (to.meta) {
        // console.log(to.meta)
        if (to.meta.grant) {
          // this route requires a grant, so check to see if they have it
          const grants = getGrants();
          const subscriptionStatus = getSubStatus();

          // console.log('grants', grants)
          if (grants[to.meta.grant] && subscriptionStatus === "current") {
            // all good, let em go
            next();
          } else {
            // they don't have the grant, they need to go pay for it
            // TODO: add pages to help them resolve issues if their status is failed or unpaid
            store.dispatch(
              "setErrorMessage",
              "You will need to upgrade to access that page.",
            );
            next({
              path: "/upgrade",
            });
          }
        } else if (to.meta.role) {
          // if role is required, check for that
          if (to.meta.role === role) {
            next();
          } else {
            store.dispatch(
              "setErrorMessage",
              "Error: your account does not have access to that page.",
            );
            next({
              path: from.path,
            });
          }
        } else {
          // meta doesn't include a grant or role requirement, let em go
          next();
        }
      } else {
        // there's no meta, so let em go
        next();
      }
    }
  },
};

// this function checks the auth status in the state of the application
// also if something funky happened to the state we reset the state
function isAuthorized() {
  const auth = store.getters.isLoggedIn;
  if (!auth) {
    // I think I fixed this with a beforeCreate function on the App.vue page
    // This shouldn't be necessary but it's a workaround for when someone reloads a page
    // const accessToken = localStorage.getItem('accessToken')
    // const refreshToken = localStorage.getItem('refreshToken')
    // if (accessToken && refreshToken) {
    //   store.dispatch('setAccessToken', accessToken)
    //   store.dispatch('setRefreshToken', refreshToken)
    //   return true
    // } else {
    //   return false
    // }
    return false;
  } else {
    return true;
  }
}

// this function grabs the grants off the user profile
function getGrants() {
  const profile = store.state.user.profile;
  if (
    profile &&
    profile.company &&
    profile.company.plan &&
    profile.company.plan.grants
  ) {
    return profile.company.plan.grants;
  }
  return {};
}

// this function gets the subscription status
function getSubStatus() {
  const profile = store.state.user.profile;
  if (profile && profile.company && profile.company.subscription) {
    return profile.company.subscription.status;
  }
  return "unpaid";
}

// this function grabs the user role of the user profile
function getRole() {
  const profile = store.state.user.profile;
  if (profile && profile.role) {
    return profile.role;
  } else {
    return "user";
  }
}
