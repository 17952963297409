// general state items to be used on the app level
// things like error messages and alerts

const state = {
    errorMessage: '',
    showEmailValidation: false,
    hideAppBar: false,
}

const actions = {
    setErrorMessage({ commit }, payload) {
        commit('setErrorMessage', payload)
    },
    clearErrorMessage({ commit }) {
        commit('clearErrorMessage')
    },
    setShowEmailValidation({ commit}, payload) {
        commit('setShowEmailValidation', payload)
    },
    setHideAppBar({ commit }, payload) {
        commit('setHideAppBar', payload)
    }
}

const mutations = {
    setErrorMessage(state, payload) {
      var errorMessage = ''
      if (payload.response) {
        if (payload.response.data) {
          if (payload.response.data.message) {
            errorMessage = payload.response.data.message
          } else {
            errorMessage = payload.response.statusText
          }
        } else {
          errorMessage = payload.response
        }
      } else {
        errorMessage = payload
      }
      state.errorMessage = errorMessage
    },
    clearErrorMessage(state) {
        state.errorMessage = ''
    },
    setShowEmailValidation(state, payload) {
        state.showEmailValidation = payload
    },
    setHideAppBar(state, payload) {
        state.hideAppBar = payload
    }
}

const getters = {
    errorMessage(state) {
        return state.errorMessage
    },
    isErrorMessage(state) {
        if (state.errorMessage === '') {
            return false
        }
        return true
    },
    showEmailValidation(state) {
        return state.showEmailValidation
    },
    hideAppBar(state) {
        return state.hideAppBar
    }
}

export default {
    state,
    actions,
    mutations,
    getters
}