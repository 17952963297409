import router from "../router";
import store from "../store";
import api from "./api";

export default {
  // basic login function
  login(creds, redirect) {
    const params = {
      email: creds.email,
      password: creds.password,
    };

    api
      .post("/auth/login", params)
      .then((response) => {
        // console.log("Check Response >>> ", response)
        // console.log("Check Redirect >>> ", redirect)
        this._storeToken(response);
        const profile = response.data.profile;
        if (!profile.emailValid) {
          store.dispatch("setShowEmailValidation", true);
          router.push({ name: "emailSent" });
        } else if (redirect) {
          if (response.data.profile.role === "cp") {
            router.push({ name: "partner-dashboard" });
          } else {
            router.push({ path: redirect });
          }
        }
        return;
      })
      .catch((err) => {
        store.dispatch("setErrorMessage", err);
        return;
      });
  },

  // basic login function
  getLinkedin(creds, redirect) {
    const params = {
      verifyCode: creds.verifyCode,
    };
    console.log("CHECK VVVV >>> ", params);
    console.log("CHECK Redirect URL >>> ", redirect);

    api
      .post("/auth/getLinkedin", params)
      .then((response) => {
        this._storeToken(response);
        if (response.data.profile.role === "cp") {
          router.push({ name: "partner-dashboard" });
        } else if (redirect) {
          router.push({ path: redirect });
        }
        return;
      })
      .catch((err) => {
        store.dispatch("setErrorMessage", err);
        return;
      });
  },

  // password reset
  reset(creds) {
    const params = {
      email: creds.email,
    };
    api
      .post("/auth/reset", params)
      .then(() => {
        router.push({ name: "resetSent" });
      })
      .catch((err) => {
        store.dispatch("setErrorMessage", err);
        return;
      });
  },

  // set a new password after resetting the password
  setPassword(creds) {
    const params = {
      password: creds.password,
      resetCode: creds.resetCode,
    };
    api
      .post("/auth/password", params)
      .then(() => {
        router.push({ name: "login" });
      })
      .catch((err) => {
        store.dispatch("setErrorMessage", err);
        return;
      });
  },

  // logout of the app
  logout() {
    // clear the user state
    store.dispatch("setProfile", null);
    store.dispatch("setAccessToken", null);
    store.dispatch("setRefreshToken", null);
    // clear localstorage
    localStorage.clear();
    router.push({ name: "login" });
  },

  _storeToken(response) {
    // expecting a response with an auth object containing tokens and a profile object containing user information
    const auth = {
      accessToken: response.data.accessToken,
      refreshToken: response.data.refreshToken,
    };
    const profile = response.data.profile;
    store.dispatch("setAccessToken", auth.accessToken);
    store.dispatch("setRefreshToken", auth.refreshToken);
    // refresh token returns a null profile
    if (profile) {
      store.dispatch("setProfile", profile);
      localStorage.setItem(
        "profile",
        JSON.stringify({
          firstName: profile.firstName,
          lastName: profile.lastName,
          role: profile.role,
          company: {
            subscription: profile.company.subscription,
            plan: profile.company.plan,
          },
        })
      );
      if (profile.company.plan.name === "intelligent") {
        if (!profile.company.plan.grants.policy) {
          store.dispatch("setErrorMessage", "Error with subscription, please contact SmarterRisk support.");
        }
      }
    }

    // store token information in localstorage
    localStorage.setItem("accessToken", auth.accessToken);
    localStorage.setItem("refreshToken", auth.refreshToken);
  },
};
