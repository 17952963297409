//import guard from "@/router/guard";
// routes for users to signup, login, verify email, etc. Normal users here.
export default [
  {
    path: "/login",
    name: "login",
    component: function (resolve) {
      require(["@/components/login/Login.vue"], resolve);
    },
  },
  {
    path: "/signup",
    name: "signup",
    component: function (resolve) {
      require(["@/components/signup/Signup.vue"], resolve);
    },
  },
  {
    path: "/plans",
    name: "plans",
    component: function (resolve) {
      require(["@/components/signup/Plans.vue"], resolve);
    },
  },
  {
    path: "/signup/emailSent",
    name: "emailSent",
    component: function (resolve) {
      require(["@/components/signup/EmailSent.vue"], resolve);
    },
  },
  {
    path: "/signup/complete/:slug",
    name: "completeSignup",
    props: true,
    component: function (resolve) {
      require(["@/components/signup/CompleteSignup.vue"], resolve);
    },
  },
  {
    path: "/signup/complete",
    name: "completeTheSignup",
    component: function (resolve) {
      require(["@/components/signup/CompleteSignup.vue"], resolve);
    },
  },
  {
    path: "/resetSent",
    name: "resetSent",
    component: function (resolve) {
      require(["@/components/login/ResetSent.vue"], resolve);
    },
  },
  {
    path: "/resetPassword/:slug",
    name: "resetPassword",
    props: true,
    component: function (resolve) {
      require(["@/components/login/NewPassword.vue"], resolve);
    },
    // beforeEnter: guard.route
  },
  {
    path: "*",
    name: "not-found",
    component: function (resolve) {
      require(["@/components/dashboard/NotFound.vue"], resolve);
    },
  },
];
