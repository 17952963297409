import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import admin from "./groups/admin"
import auth from "./groups/auth"
import arc from "./groups/arc"
import user from "./groups/user"

const router = new Router({
  mode: 'history',
  routes: [
    ...admin,
    ...auth,
    ...arc,
    ...user
  ]
})

export default router