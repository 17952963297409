import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#133A7C',
        secondary: '#06AAFC',
        accent: '#B9DFF9',
        error: '#f44336',
        warning: '#ffc107',
        info: '#df741b',
        success: '#137c1f'
      }
    }
  }
});
