// store the ares instance for the user
const state = {
  tmpClients: JSON.parse(localStorage.getItem('addList') || '[]'),
}

const actions = {
  addClient({ commit }, payload) {
    commit('addClient', payload)
  },
  delClient({ commit }, payload) {
    commit('delClient', payload)
  },
  initOrder({ commit }) {
    commit('initOrder')
  }
}

const mutations = {
  addClient(state, payload) {
    state.tmpClients.push(payload)
    localStorage.setItem("addList", JSON.stringify(state.tmpClients));
  },
  delClient(state, payload) {
    let index = state.tmpClients.indexOf(payload);
    state.tmpClients.splice(index, 1);
    localStorage.setItem("addList", JSON.stringify(state.tmpClients));
  },
  initOrder(state) {
    localStorage.setItem("addList", "[]");
    // for (var i = 0; i++; i < state.tmpClients.length) {
    //   state.tmpClients.splice(i, 1);
    // }
    state.tmpClients = []
  }
}

const getters = {
  tmpClients(state) {
    return state.tmpClients
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}