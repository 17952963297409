import guard from "@/router/guard";

export default [
  {
    path: '/admin',
    name: 'manageUserAccounts',
    component: function (resolve) {
      require(['@/components/admin/ManageUserAccount.vue'], resolve)
    },
    beforeEnter: guard.admin
  },
  {
    path: '/admin/user/:id',
    name: 'userdetail',
    component: function (resolve) {
      require(['@/components/admin/UserDetails.vue'], resolve)
    },
    beforeEnter: guard.admin
  },
]