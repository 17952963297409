import guard from "@/router/guard";
// TODO: Rename everything from "arc" to "partner"
// We used to refer to channel partners as "ARC" customers. Now they are just partners
export default [
  {
    path: "/partner",
    name: "partner-dashboard",
    component: function (resolve) {
      require(["@/components/partner/Dashboard.vue"], resolve);
    },
    meta: { role: "cp" },
    beforeEnter: guard.route,
  },
  // {
  //   path: '/partner/addclient',
  //   name: 'partner-add-client',
  //   component: function (resolve) {
  //     require(['@/components/partner/AddClientForm.vue'], resolve)
  //   },
  //   meta: { role: 'cp' },
  //   beforeEnter: guard.route
  // },
  {
    path: "/partner/signup/:ptype", // signup page for a new channel partner. This user is the admin by default
    name: "partner-signup",
    component: function (resolve) {
      require(["@/components/partner/Signup.vue"], resolve);
    },
  },
  {
    path: "/partner/signup", // signup page for a new channel partner. This user is the admin by default
    name: "partner-catchsignup",
    component: function (resolve) {
      require(["@/components/partner/Signup.vue"], resolve);
    },
  },
  {
    path: "/partner/account",
    name: "partner-account",
    component: function (resolve) {
      require(["@/components/partner/Account.vue"], resolve);
    },
    beforeEnter: guard.route,
  },
  {
    path: "/partner/billing",
    name: "partner-billing",
    component: function (resolve) {
      require(["@/components/partner/Billing.vue"], resolve);
    },
    meta: { role: "cp" },
    beforeEnter: guard.route,
  },
  {
    path: "/partner/pricing",
    name: "partner-pricing",
    component: function (resolve) {
      require(["@/components/partner/Pricing.vue"], resolve);
    },
    meta: { role: "cp" },
    beforeEnter: guard.route,
  },
  {
    path: "/partner/signup/complete/:slug", // verifying new partner signup (admin)
    name: "partner-complete-signup",
    props: true,
    component: function (resolve) {
      require(["@/components/partner/CompleteSignup.vue"], resolve);
    },
  },
  {
    path: "/partner/signup/complete",
    name: "partner-complete-the-signup",
    component: function (resolve) {
      require(["@/components/partner/CompleteSignup.vue"], resolve);
    },
  },
  {
    path: "/partner/user/signup/complete/:slug", // verifying the clients of partner users
    name: "partner-complete-user-signup",
    props: true,
    component: function (resolve) {
      require(["@/components/partner/VerifyUser.vue"], resolve);
    },
  },
  {
    path: "/partner/user/signup/complete",
    name: "partner-complete-the-user-signup",
    component: function (resolve) {
      require(["@/components/partner/VerifyUser.vue"], resolve);
    },
  },
  {
    path: "/partner/client/signup/complete/:slug", // verifying the clients of partner users
    name: "partner-complete-client-signup",
    props: true,
    component: function (resolve) {
      require(["@/components/partner/VerifyClient.vue"], resolve);
    },
  },
  {
    path: "/partner/client/signup/complete",
    name: "partner-complete-the-client-signup",
    component: function (resolve) {
      require(["@/components/partner/VerifyClient.vue"], resolve);
    },
  },
  {
    path: "/partner/client/report/:clientID",
    name: "partner-client-report",
    props: true,
    component: function (resolve) {
      require(["@/components/partner/ClientReport.vue"], resolve);
    },
  },
  {
    path: "/partner/client/report",
    name: "partner-the-client-report",
    component: function (resolve) {
      require(["@/components/partner/ClientReport.vue"], resolve);
    },
  },
  {
    path: "/partner/client/recs/:clientID",
    name: "partner-client-recs",
    props: true,
    component: function (resolve) {
      require(["@/components/partner/ClientRecs.vue"], resolve);
    },
  },
  // TODO: add /partner/client/recs/ with a :clientID as a slug - this is a page to show recs
  // TODO: add /partner/client/ with a :clientID as a slug - this is a page to show a client summary
];
