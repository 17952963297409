import guard from "@/router/guard";

export default [
  {
    path: "/",
    name: "dashboard",
    component: function (resolve) {
      require(["@/components/dashboard/Dashboard.vue"], resolve);
    },
    beforeEnter: guard.route,
  },
  {
    path: "/assessment",
    name: "riskAssessment",
    component: function (resolve) {
      require(["@/components/assessment/Assessment.vue"], resolve);
    },
    beforeEnter: guard.route,
  },
  {
    path: "/info/company",
    name: "companyInfo",
    component: function (resolve) {
      require(["@/components/signup/CompanyInfo.vue"], resolve);
    },
    beforeEnter: guard.route,
  },
  {
    path: "/info/locations",
    name: "locationInfo",
    component: function (resolve) {
      require(["@/components/account/LocationInfo.vue"], resolve);
    },
    beforeEnter: guard.route,
  },
  {
    path: "/addLocations",
    name: "addLocations",
    component: function (resolve) {
      require(["@/components/assessment/AddLocations.vue"], resolve);
    },
    meta: {
      grant: "locations",
    },
    beforeEnter: guard.route,
  },
  {
    path: "/forms",
    name: "formlibrary",
    component: function (resolve) {
      require(["@/components/formsLibrary/FormsLibrary.vue"], resolve);
    },
    // meta: {
    //   grant: 'forms'
    // },
    beforeEnter: guard.route,
  },
  {
    path: "/policy",
    name: "policybuilder",
    component: function (resolve) {
      require(["@/components/PolicyManager/PolicyBuilder.vue"], resolve);
    },
    // meta: {
    //   grant: 'policy'
    // },
    beforeEnter: guard.route,
  },
  {
    path: "/policydetails",
    name: "policydetails",
    component: function (resolve) {
      require(["@/components/PolicyManager/PolicyDetails.vue"], resolve);
    },
    meta: {
      grant: "policy",
    },
    beforeEnter: guard.route,
  },
  {
    path: "/report/:assIndex",
    name: "report",
    component: function (resolve) {
      require(["@/components/report/Report.vue"], resolve);
    },
    props: true,
    // I had to remove this because, for some reason, it kept sending people to the upgrade page
    // I think it has something to do with opening a new window and losing application state
    // For now, I'm handling this check in the report.vue file
    // meta: {
    //   grant: "report",
    // },
    beforeEnter: guard.route,
  },
  {
    path: "/report/:assIndex/:download",
    name: "report-dl",
    component: function (resolve) {
      require(["@/components/report/Report.vue"], resolve);
    },
    props: true,
    // meta: {
    //   grant: "report",
    // },
    beforeEnter: guard.route,
  },
  {
    path: "/upgrade",
    name: "upgrade",
    component: function (resolve) {
      require(["@/components/account/Upgrade.vue"], resolve);
    },
    beforeEnter: guard.route,
  },
  {
    path: "/account",
    name: "account",
    component: function (resolve) {
      require(["@/components/account/Account.vue"], resolve);
    },
    beforeEnter: guard.route,
  },
];
