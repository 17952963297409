// store the ares instance for the user
const state = {
  ares: {},
}

const actions = {
  setAres({ commit }, payload) {
    commit('setAres', payload)
  }
}

const mutations = {
  setAres(state, payload) {
    state.ares = payload
  }
}

const getters = {
  ares(state) {
    return state.ares
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}